












import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import OnboardingProviders from '@/views/OnboardingView/onboardingStepsComponents/onboardingContents/OnboardingProviders.vue';
import CogIcon from '@/assets/images/icons/cog-icon.svg?inline';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import { EOnbordingStep } from '@/api/schema';

export default {
  name: 'OnboardingGameProviders',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    OnboardingProviders,
    CogIcon
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.gameProviders
  })
};
