
















































import { mapActions, mapGetters, mapState } from 'vuex';
import { GameProviderData } from '@/api/schema';
import VStatus from '@/components/VStatus.vue';

export default {
  name: 'OnboardingProviders',
  filters: {
    status(val: boolean): string {
      return val ? 'Active' : 'Disabled';
    },
    name(val: string): string {
      return val?.replaceAll('_', ' ');
    }
  },
  components: { VStatus },
  data: (): unknown => ({
    search: '',
    panel: null,
    headers: [
      {
        text: 'Providers',
        value: 'name'
      },
      {
        text: 'Status',
        value: 'connected',
        width: '30%'
      },
      {
        text: 'Action',
        value: 'action',
        width: '200px'
      }
    ],
    searchQuery: ''
  }),
  computed: {
    ...mapState('Onboarding', ['providers']),
    ...mapGetters('Onboarding', ['operatorId']),
    sortedProviders(): GameProviderData[] {
      return [...this.providersList].sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    providersList(): GameProviderData[] {
      if (!this.searchQuery || !this.searchQuery.length) {
        return this.providers;
      }

      return [...this.providers].filter((provider) => {
        return provider.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
  },
  methods: {
    clearSearch(): void {
      this.$nextTick(() => {
        this.search = '';
        this.filterProviders();
      });
    },
    filterProviders(): void {
      if (!this.search || !this.search.length) {
        this.searchQuery = '';

        return;
      }

      this.searchQuery = this.search;
    },
    ...mapActions('Onboarding', ['showProviderDialog'])
  }
};
